
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Attachment from '@/components/common/Attachments.vue';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'trainer-editing',
    components: {
      SearchField,
      ErrorMessage,
      Field,
      Form,
      Attachment,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const selectedNationality = ref<number>(0);
      const selectedGender = ref<number>(0);
      const selectedStatuses = ref<number>(0);
      const selectedCategories = ref<Array<string>>([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('trainerUpdating'), [
          translate('operations'),
        ]);
        await store.dispatch(Actions.GET_ALL_SETTINGS_CLASS_CATEGORY);
      });

      const { data } = await store.dispatch(
        Actions.GET_TRAINER,
        route.params.id
      );
      data.data.classCategories?.map((category) => {
        selectedCategories.value.push(category.id);
      });
      if (selectedCategories.value.length === 0) {
        selectedCategories.value.push(data.data.classCategoryId);
      }

      const trainer = ref(data.data);
      const attachmentsObj = trainer.value.attachments.reduce(
        (acc, attachment) => {
          const documentType = attachment.documentType;

          if (!acc[documentType]) {
            acc[documentType] = [];
          }

          acc[documentType].push(attachment);

          return acc;
        },
        {}
      );

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        shortName: Yup.string()
          .required(() => translate('Short Name is required field'))
          .label('Short Name'),
        color: Yup.string()
          .required(() => translate('Color is required field'))
          .label('Color'),
        nationalityId: Yup.string()
          .notOneOf(['0'], () => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .required(() => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .label('nationalityId'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('genderId'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Statuses'),
        civilId: Yup.string()
          .matches(/^\d{12}$/, {
            message: () => translate('Civil id must be 12 digits'),
          })
          .required(() => translate('CIVIL_IS_REQUIRED_FIELD'))
          .label('civil'),

        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('address'),
        classLimitPerDay: Yup.number()
          .required(() => translate('CLASS_LIMIT_IS_REQUIRED_FIELD'))
          .label('class limit'),
        classCategoryId: Yup.array()
          .min(1, () => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD'))
          .required(() => translate('CLASS_CATEGORY_IS_REQUIRED_FIELD'))
          .label('class category'),
        dateOfBirth: Yup.string()
          .required(() => translate('DATE_IS_REQUIRED_FIELD'))
          .label('Date Of Birth'),
        phone: Yup.string()
          .matches(/^\d{8}$/, {
            message: () => translate('Phone number is not valid'),
          })
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD')),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
      });

      const onSubmitEdit = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.classLimitPerDay = Number(values.classLimitPerDay);
        values.mobile = '965+' + values.phone;
        values.classCategoryId = selectedCategories.value;
        values.yearsOfExperience = String(values.yearsOfExperience);

        await store.dispatch(Actions.UPDATE_TRAINER, {
          data: values,
          id: trainer.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_TRAINER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      const HandleDateOfBirth = (dateInput) => {
        console.log(dateInput);
        trainer.value.dateOfBirth = dateInput;
      };

      const deleteAttachment = async (id) => {
        const result = await Swal.fire({
          title: translate('You are about to delete this attachment'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_TRAINER_ATTACHMENT, id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('Successfully deleted attachment'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
            location.reload();
          }
        }
      };

      const onSubmitEditAttachment = async (values, cb) => {
        await store.dispatch(Actions.CREATE_TRAINER_ATTACHMENT, {
          data: values,
          id: trainer.value.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          location.reload();
        }
        cb();
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.GENDER,
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.DOCUMENT_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const genders = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GENDER;
      });
      const nationalities = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statuses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });

      const categories = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );

      const documents = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      return {
        onSubmitEdit,
        documents,
        HandleDateOfBirth,
        onSubmitEditAttachment,
        trainer,
        selectedCategories,
        selectedStatuses,
        selectedGender,
        selectedNationality,
        categories,
        statuses,
        genders,
        nationalities,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        attachmentsObj,
        deleteAttachment,
      };
    },
  });
